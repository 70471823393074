import styled from '@emotion/styled';
import { Anchor } from '../Anchor';
import { ButtonProps } from './Button';

export const StyledButton =
    styled.button <
    ButtonProps >
    `
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
    white-space: nowrap;

    background: ${props =>
        (props.kind === 'success' && props.theme.colors.success[500]) ||
        (props.kind === 'danger' && props.theme.colors.danger[500]) ||
        props.fill ||
        props.theme.colors.primary[500]};

    color: ${props =>
        props.fill === 'white' || props.fill === 'transparent'
            ? '#000'
            : '#fff'};
    text-align: center;
    // border-radius: ${props => props.theme.radii.lg};

    text-decoration: none;
    outline: none;
    border: 0;
    :hover,
    :active {
        outline: none;
    }
    :focus {
        outline: 2px solid #2B2BE1;
    }

    height: 36px;

    cursor: pointer;

    width: ${props =>
        (props.width && `${props.width}px`) || (props.block && '100%')};

    opacity: ${props =>
        (props.isFetching && 0.5) || (props.disabled && 0.5) || 1};
    pointer-events: ${props =>
        (props.isFetching && 'none') ||
        (props.disabled && 'none') ||
        'initial'};

    &:hover {
        opacity: 0.8;
    }
`;

export const StyledButtonContent = styled.div`
    text-transform: uppercase;
    display: flex;
    font-size: 11px;
    font-weight: ${props => props.theme.fontWeights.bold};
    letter-spacing: 0.5px;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    align-items: center;
`;

export const StyledIcon = styled.div`
    margin-right: 10px;
`;

export const StyledAnchor = StyledButton.withComponent(Anchor);
