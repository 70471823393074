import styled from '@emotion/styled';
import React from 'react';
import { StyledAnchor, StyledButton, StyledButtonContent, StyledIcon } from './Button.styled';

export type ButtonProps = {
    type?: 'button' | 'submit';
    kind?: 'primary' | 'success' | 'danger';
    block?: boolean;
    href?: string;
    icon?: React.ComponentType<import('mdi-react').MdiReactIconProps>;
    iconSize?: number;
    children?: string | React.ReactNode;
    isFetching?: boolean;
    disabled?: boolean;
    noPadding?: boolean;
    fill?: 'black' | 'white' | 'transparent';
    onClick?: React.EventHandler<React.MouseEvent<HTMLElement>>;

    // allow custom width, mainly for smooth button switching transitions
    style?: any;
    width?: number;
};

function ButtonComponent(props: ButtonProps) {
    const { href, icon: ButtonIcon, iconSize = 18, type = 'button', children, disabled, ...rest } = props;

    const ButtonTag = href ? StyledAnchor : StyledButton;

    return (
        <ButtonTag href={href} type={type} disabled={disabled} width={props.width} {...rest}>
            <StyledButtonContent>
                {ButtonIcon && (
                    <StyledIcon>
                        <ButtonIcon size={iconSize} />
                    </StyledIcon>
                )}
                {children}
            </StyledButtonContent>
        </ButtonTag>
    );
}

/**
 * Another way to handle refs
 * https://www.styled-components.com/docs/advanced#referring-to-other-components
 * */
export const Button = styled(ButtonComponent)``;
