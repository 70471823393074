import React from 'react';
import { Container, ItemContainer } from './MegaMenu.styled';

export function MegaMenu({
    children,
    onMouseOver,
    onMouseOut,
    className,
    ...props
}: any) {
    return (
        <Container
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            className={className}
            px={10}
            py={10}
            {...props}
        >
            {children}
        </Container>
    );
}

export function MegaMenuItem({ children, active, ...props }: any) {
    return (
        <ItemContainer
            color="bodyText"
            // mx={10}
            mx="spacing-lg"
            // p={16}
            p="spacing-xl"
            active={active}
            {...props}
        >
            {children}
        </ItemContainer>
    );
}
