import { Form } from '@audentio/stuff/src/Form';
import { FormControl } from '@audentio/stuff/src/FormControl';
import { Input as DefaultInput } from '@audentio/stuff/src/Input';
import { InputProps } from '@audentio/stuff/src/Input/types';
import { useLocation } from '@reach/router';
import { useTheme } from 'emotion-theming';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import React, { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Helmet } from 'react-helmet';
import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { Flex } from 'src/components/Flex';
import { Heading } from 'src/components/Heading';
import { string } from 'yup';
import { DualHeading } from '../DualHeading';

const CAPTCHA_SITE_KEY = process.env.GATSBY_CAPTCHA_SITE_KEY;
const Sentry = require('@sentry/browser');

const Input = (props: InputProps) => (
    <DefaultInput
        {...props}
        height="input.lg"
        fontSize="body"
        fontFamily="inherit"
        border="0"
        color="boldText"
        backgroundColor="transparent"
        borderBottom="1px solid"
        borderBottomColor="border"
        px={0}
        borderRadius={0}
        _focus={{
            backgroundColor: 'transparent',
            borderBottomColor: 'inputFocus',
        }}
        _hover={{
            backgroundColor: 'transparent',
        }}
    />
);

export function ContactForm({
    title = "Let's get started.",
    source = 'ac_website_contact',
}: any) {
    const theme = useTheme();
    const [formValue, setFormValue] = useState({});
    const [{ transition, error, isFetching }, setState] = useState({
        transition: null,
        error: null,
        isFetching: false,
    });

    // eslint-disable-next-line prettier/prettier
    const recaptchaRef = useRef<ReCAPTCHA>();

    const location = useLocation();

    source = source
        .toLowerCase()
        .split(' ')
        .join('_');

    const handleSubmit = async (e, { getFormValue }) => {
        const value: any = getFormValue();
        const { email: sender, message, name, phone, org } = value;

        if (
            !sender ||
            !message ||
            !name ||
            !sender.length ||
            !message.length ||
            !name.length
        ) {
            setState({
                isFetching: false,
                error: null,
                transition: 'incomplete',
            });
            return;
        }

        setState({ isFetching: true, transition: null, error: null });

        recaptchaRef.current.executeAsync().then(token => {
            // not sensitive
            const hsFormId = '1faaa651-1121-4cc6-a8bd-52cd40efa9f2';

            // bridges the current pathname with the expected value of the hubspot form
            const submittedPageBridgedVal =
                location?.pathname === '/' ? 'Homepage' : location?.pathname;

            const additionalHsFields = [
                {
                    objectTypeId: '0-1',
                    name: 'ac_form_submitted_page_s_',
                    value: submittedPageBridgedVal,
                },
            ];

            const params = {
                sender,
                message,
                name,
                phone,
                org,
                source,
                recaptchaToken: token,
                formId: hsFormId,
                additionalHsFields,
                pageUri: window.location.href,
            };

            if (token) {
                fetch('https://www.audent.io/api/contact', {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    method: 'POST',
                    body: JSON.stringify(params),
                })
                    .then(response => response.json())
                    .then(response => {
                        if (response.success) {
                            setFormValue({});
                            setState({
                                error: null,
                                isFetching: false,
                                transition: 'success',
                            });

                            // TRACK GA EVENT
                            trackCustomEvent({
                                // required - The object that was interacted with (e.g.video)
                                category: 'Contact Form',
                                // required - action - Type of interaction (e.g. 'play')
                                action: 'Form Submitted',
                                // optional - label - page of the submission - Useful for categorizing events
                                label: source,
                            });
                        } else {
                            Sentry.configureScope(function (scope) {
                                scope.setContext('Email Params', params);
                            });
                            Sentry.captureException(
                                new Error('Send Email Promise Failed')
                            );

                            setState({
                                error: null,
                                isFetching: false,
                                transition: 'error',
                            });
                        }
                    })
                    .catch(error => {
                        Sentry.configureScope(function (scope) {
                            scope.setContext('Email Params', params);
                        });
                        Sentry.captureException(error);

                        setState({
                            isFetching: false,
                            transition: 'error',
                            error: error.toString(),
                        });
                    });
            }
        });
    };

    return (
        <Form
            onChange={({ name, value }) => {
                setFormValue($form => ({ ...$form, [name]: value }));
            }}
            value={formValue}
            // isFetching={isFetching}
            onSubmit={handleSubmit}
            datasource={source}
        >
            <Helmet>
                <script
                    type="text/javascript"
                    id="hs-script-loader"
                    async
                    defer
                    src="//js.hs-scripts.com/4911065.js"
                />
            </Helmet>

            <Flex direction={{ _: 'column', xl: 'row' }}>
                <Flex
                    pr={{ _: 0, xl: 20, full: 100 }}
                    mb="spacing-lg"
                    direction="column"
                    position="relative"
                >
                    <DualHeading
                        title={title}
                        subtitle="READY TO BUILD A COMMUNITY?"
                        subtitleColor="bodyText"
                        subtitleMarginBottom={4}
                        kind="h3"
                        mb={4}
                        mt={-20}
                    //maxWidth={480}
                    //fontSize="paragraphTitle"
                    />
                </Flex>
                <Flex direction="column" flexGrow={1}>
                    <Box textAlign="right">
                        {/* <SubmitButton transitionState={transition} isFetching={isFetching} /> */}
                        <Button
                            fill={theme.mode === 'dark' ? 'white' : 'black'}
                            style={{ minWidth: 150 }}
                            disabled={isFetching}
                            type="submit"
                            onClick={(e) => {
                                e.preventDefault();
                                const Intercom = window['Intercom']

                                Intercom("boot", {
                                    api_base: "https://api-iam.intercom.io",
                                    app_id: "awlgtkwp"
                                });

                                Intercom("show");
                            }}
                        >
                            Contact us
                        </Button>
                    </Box>
                </Flex>
            </Flex>
            {CAPTCHA_SITE_KEY && (
                <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey={CAPTCHA_SITE_KEY}
                />
            )}
        </Form>
    );
}
