import styled from '@emotion/styled';
import { Box } from 'components/Box';

export const HeaderContainer = styled.header<{ hasBorder: boolean; hasSubnav?: boolean }>`
    background: white;
    position: ${props => props.hasSubnav ? 'relative' : 'sticky' };
    width: 100%;
    top: 0;
    z-index: ${props => props.theme.zIndices.sticky + 1};
    // ${props => props.hasBorder && `border-bottom: 1px solid ${props.theme.colors.border};`}

    ${props => props.hasSubnav ? 'none' : `box-shadow: 0px 0.5px 0 rgba(0, 0, 0,0.1), ${props.theme.shadows.topNav}`};
`;

export const HeaderContent =styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: ${props => props.theme.headerHeight};
`

export const SubNav = styled(Box)`
    position: sticky;
    top: -1;
    z-index: ${props => props.theme.zIndices.sticky};
    box-shadow:  ${props => `0px 0.5px 0 ${props.theme.colors.border}, inset 0px 0.5px 0 ${props.theme.colors.border}, ${props.theme.shadows.topNav}`};
    // background-color: ${props => props.theme.colors.secondaryBackground};
    background-color: rgba(250,250,250,0.9);
    /* backdrop-filter: blur(30px); */

    > div {
        overflow: auto;
    }

    strong {
        font-weight: ${props => props.theme.fontWeights.medium};
        color: ${props => props.theme.colors.boldText};
    }

    & .contentWidth {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    ol {
        list-style-type: none;
        margin: 0;
        padding: 0;
        height: 48px;
        display: flex;
        flex-direction: row;
        align-items: center;
        // align-self: flex-end;
        // justify-content: flex-end;

        li {
            display: inline-block;
            flex-shrink: 0;
        }

        li a {
            display: inline-block;
            padding: 7px 0;
            margin-left: ${props => props.theme.sizes[16]};
            text-transform: uppercase;
            font-size: ${props => props.theme.fontSizes.smallSubtitle};
            font-weight: ${props => props.theme.fontWeights.medium};
            color: ${props => props.theme.colors.bodyText};
            letter-spacing: 0.5px;

            &:hover {
                color: ${props => props.theme.colors.boldText};
            }
        }

        @media (max-width: ${props => props.theme.breakpoint.md}) {
            li:last-child a {
                margin-right:  ${props => props.theme.sizes[8]};
            }
        }
    }
`;

export const MethodSubNavComp = styled(Box)`
    position: sticky;
    top: -1px;
    z-index: ${props => props.theme.zIndices.sticky};
    box-shadow:  ${props => `0px 0.5px 0 ${props.theme.colors.border}, inset 0px 0.5px 0 ${props.theme.colors.border}, ${props.theme.shadows.topNav}`};
    // background-color: ${props => props.theme.colors.secondaryBackground};
    background-color: #000;
    /* backdrop-filter: blur(30px); */
    /* position: relative; */
    

    > div {
        overflow: auto;
    }

    strong {
        font-weight: ${props => props.theme.fontWeights.medium};
        color: ${props => props.theme.colors.boldText};
        text-transform: uppercase;
        font-size: 1.2rem;
    }

    & .contentWidth {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        img {
            margin-right: 10px;
        }

    }

    ol {
        list-style-type: none;
        margin: 0;
        padding: 0;
        height: 48px;
        display: flex;
        flex-direction: row;
        align-items: center;
        // align-self: flex-end;
        // justify-content: flex-end;

        li {
            display: inline-block;
            flex-shrink: 0;
        }

        li a {
            display: inline-block;
            padding: 7px 0;
            margin-left: ${props => props.theme.sizes[16]};
            text-transform: uppercase;
            font-size: ${props => props.theme.fontSizes.smallSubtitle};
            font-weight: ${props => props.theme.fontWeights.medium};
            color: #fff;
            letter-spacing: 0.5px;

            &:hover {
                color: rgba(255,255,255,.8);
            }
        }

        @media (max-width: ${props => props.theme.breakpoint.md}) {
            li:last-child a {
                margin-right:  ${props => props.theme.sizes[8]};
            }
        }
    }
`;