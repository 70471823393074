import React from 'react';
import { MegaMenu, MegaMenuItem } from '../MegaMenu';
import LogoSvg from './Logo.svg';
import LogoAdjournSvg from './LogoAdjourn.svg';
import LogoAudSvg from './LogoAud.svg';

export function SiteSwitcher({ onMouseOver, onMouseOut, className }: any) {
    return (
        <MegaMenu
            className={className}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            p={4}
        >
            <MegaMenuItem
                href="https://audent.io"
                target="_self"
                width="100%"
                flexDirection="column"
                lineHeight="1"
                mx={4}
                mb={4}
            >
                <LogoAudSvg />
            </MegaMenuItem>

            <MegaMenuItem
                href="/"
                mx={4}
                mb={4}
                lineHeight="1"
                flexDirection="column"
                active
            >
                <LogoSvg />
            </MegaMenuItem>

            <MegaMenuItem
                href="https://adjourn.audent.io"
                target="_self"
                width="100%"
                mx={4}
                lineHeight="1"
                flexDirection="column"
            >
                <LogoAdjournSvg />
            </MegaMenuItem>
        </MegaMenu>
    );
}
