import { Location } from '@reach/router';
import { graphql, StaticQuery } from 'gatsby';
import schemaGenerator from 'helpers/schemaGenerator';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Favicon } from '../Favicon';
import { useSystemColorScheme } from './useSystemColorScheme';

const HeadComponent = ({
    siteTitle,
    siteDescription,
    siteUrl,
    pageTitle,
    //pageTitleFull = pageTitle ? `${siteTitle} – ${pageTitle}` : siteTitle,
    pageTitleFull = `${siteTitle} - ${pageTitle}`,
    themeColor,
    social,
    imageUrl,
    location,
    canonical = siteUrl + (location.pathname || ''),
}) => {
    const colorScheme = useSystemColorScheme();

    return (
        <Helmet>
            <html lang="en" />

            <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
            <meta
                content="width=device-width, initial-scale=1"
                name="viewport"
            />

            <meta content={siteTitle} name="apple-mobile-web-app-title" />
            <meta content={pageTitleFull} property="og:title" />
            <meta content={pageTitleFull} name="twitter:title" />
            <title>{pageTitleFull}</title>

            <meta content={siteDescription} name="description" />
            <meta content={siteDescription} property="og:description" />
            <meta content={siteDescription} name="twitter:description" />

            <meta content="yes" name="apple-mobile-web-app-capable" />
            <meta
                content="black-translucent"
                name="apple-mobile-web-app-status-bar-style"
            />
            <meta content={themeColor} name="theme-color" />
            <meta content={siteTitle} name="application-name" />

            <meta content="website" property="og:type" />
            <meta content={siteTitle} property="og:site_name" />
            {/* <meta content={social.fbAppId} property="fb:app_id" /> */}
            <meta content="summary_large_image" name="twitter:card" />
            <meta content={`@${social.twitter}`} name="twitter:site" />
            <meta content={`@${social.twitter}`} name="twitter:creator" />
            <meta content={pageTitleFull} name="twitter:text:title" />
            <meta content={canonical} property="og:url" />
            <meta content={canonical} name="twitter:url" />
            <link rel="canonical" href={canonical} />

            <meta
                content={imageUrl || `${siteUrl}/social.png`}
                property="og:image"
            />
            <meta content="1024" property="og:image:width" />
            <meta content="512" property="og:image:height" />
            <meta
                content={imageUrl || `${siteUrl}/social.png`}
                name="twitter:image"
            />
            <meta content="1024" name="twitter:image:width" />
            <meta content="512" name="twitter:image:height" />
            <meta
                content={imageUrl || `${siteUrl}/social.png`}
                property="og:image"
            />
            <meta content="1024" property="og:image:width" />
            <meta content="512" property="og:image:height" />

            <meta content={themeColor} name="msapplication-TileColor" />

            <link href="/manifest.json" rel="manifest" />

            {/* calling as function because Helmet doesn't allow components. returning array directly works */}
            {Favicon({ colorScheme })}

            <script type="application/ld+json">
                {JSON.stringify(
                    schemaGenerator({
                        location,
                        canonical,
                        siteUrl,
                        pageTitle,
                        siteTitle,
                        pageTitleFull,
                    })
                )}
            </script>

            <script>
                {`
// We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/awlgtkwp'
(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/awlgtkwp';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
`}
            </script>
        </Helmet>
    );
};

HeadComponent.propTypes = {
    siteTitle: PropTypes.string,
    siteTitleShort: PropTypes.string,
    siteDescription: PropTypes.string,
    siteUrl: PropTypes.string,
    themeColor: PropTypes.string,
    social: PropTypes.objectOf(PropTypes.string),
    imageUrl: PropTypes.string,
    canonical: PropTypes.string,
    pageTitle: PropTypes.string,
    pageTitleFull: PropTypes.string,
    location: PropTypes.object.isRequired,
};

export const Head = props => (
    <StaticQuery
        query={graphql`
            query {
                site {
                    siteMetadata {
                        siteTitle
                        siteTitleShort
                        siteDescription
                        siteUrl
                        themeColor
                        social {
                            twitter
                            fbAppId
                        }
                    }
                }
            }
        `}
        render={data => (
            <Location>
                {({ location }) => (
                    <HeadComponent
                        {...data.site.siteMetadata}
                        {...props}
                        location={location}
                    />
                )}
            </Location>
        )}
    />
);
