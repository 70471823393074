// import { componentStyleDef } from '../theme/types';
// import { useTheme } from '../ThemeProvider';
// import { HeadingProps } from './types';

// export const headingStyle: componentStyleDef<HeadingProps> = (
//     props,
//     theme
// ) => ({
//     style: {
//         display: 'block',
//         fontWeight: theme.fontWeights.medium,
//         fontFamily: 'heading',
//         lineHeight: 1.2,
//         color: 'boldText',
//         mb: '0.5em',
//         maxWidth: props.center && '85%',
//         mx: props.center && 'auto',
//         textAlign: props.center && 'center',
//     },
// });

// const useHeadingStyle = (props: HeadingProps) => {
//     const theme = useTheme();
//     const styles = theme['styles'].heading
//         ? theme['styles'].heading(props, theme)
//         : headingStyle(props, theme);

//     return styles.style;
// };

// export default useHeadingStyle;

import { componentStyleDef } from '../theme/types';
import { useTheme } from '../ThemeProvider';
import { HeadingProps } from './types';

const scaleRatio = 1.25;
const sizeBase = 16;

function reverseNumber(n, min, max) {
    return max + min - n;
}

const getKind = kind => {
    return {
        fontSize: `calc(${sizeBase + 2 * kind}px + ${0.175 * kind}vw)`,
        lineHeight: '1.3',
    };
};

export const headingStyle: componentStyleDef<HeadingProps> = (
    { kind, center },
    theme
) => ({
    scaleRatio,
    style: {
        display: 'block',
        fontWeight: 'bold',
        mb: '0.5rem',
        fontFamily: 'heading',
        color: 'boldText',
        maxWidth: center && '85%',
        mx: center && 'auto',
        textAlign: center && 'center',

        ...getKind(reverseNumber(Number(kind.replace('h', '')) - 1, 0, 5)),
    },
});

const useHeadingStyle = (props: HeadingProps) => {
    const theme = useTheme();
    const styles = theme['styles'].heading
        ? theme['styles'].heading(props, theme)
        : headingStyle(props, theme);

    return styles.style;
};

export default useHeadingStyle;
