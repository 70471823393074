import React from 'react';
import { Box, BoxProps } from '../Box';

export function ContentWidth({
    maxWidth = 'containers.content',
    ...props
}: BoxProps) {
    return (
        <Box maxWidth={maxWidth} marginX="auto" width="100%">
            <Box
                px={['spacing', null, 'spacing-lg']}
                className="contentWidth"
                {...props}
            />
        </Box>
    );
}
