import styled from '@emotion/styled';
import { Anchor } from 'components/Anchor';
import { Box } from 'components/Box';
import React, { useCallback } from 'react';
import { Button } from 'components/Button';
import { MegaMenu, MegaMenuItem } from 'components/MegaMenu';
import ChevronUpDownIcon from 'mdi-react/ChevronUpDownIcon';
import MenuIcon from 'mdi-react/MenuIcon';
import { Grid } from 'src/components/Grid';
import { useTheme } from 'src/components/ThemeProvider';
import { Container } from './Nav.styled';

const PrinciplesMenuLi = styled(Box)`
    position: relative;

    .principlesMenu {
        transform: translateY(-10px);
        width: 200px;
        left: auto;
        right: 0;
        margin-top: 6px;
        flex-direction: row;
        transition-delay: 50ms;
        font-size: ${props => props.theme.fontSizes.smallBody};
        padding: 8px;
    }

    &:hover,
    &:focus,
    &:focus-within {
        .principlesMenu {
            transform: none;
            opacity: 1;
            visibility: visible;
        }
    }
`;

const principles = [
    'Foundation',
    'Experience',
    'Wellness',
    'Monetization',
    'Onboarding',
    'Engagement',
    'Gamification',
    'Optimization',
];

const methods = [
    ['Strategize', 'strategize'],
    ['Build', 'build'],
    ['Manage', 'manage'],
    ['Maintain', 'maintain'],
    ['App', 'app'],
    ['Metrics', 'metrics'],
    ['Resources', 'resources'],
    ['Live Events', 'streaming'],
];

const forCommunities = [
    // Name - route end
    ['Publisher', 'publisher'],
    ['Non-Profit', 'nonprofit'],
    ['Membership', 'membership'],
    ['Discord Management', 'discord-community-management'],
    ['Discord Development', 'discord-custom-bot'],
];

const PrincipleMenuItem = ({ children, href, ...props }) => (
    <MegaMenuItem
        href={href}
        color="boldText"
        style={{
            justifyContent: 'flex-start',
            width: '100%',
        }}
        p={6}
        px={8}
        mx={0}
        {...props}
    >
        {children}
    </MegaMenuItem>
);

const MenuContainer = styled(Box)`
    position: relative;

    .menu {
        margin-top: 3px;
        transform: translateY(-10px);
        transition-delay: 400ms;
        padding: 10px;
        /* width: 250px; */
        left: auto;
        right: 0;
    }

    &:hover {
        .menu {
            transition-delay: 0ms;
            opacity: 1;
            visibility: visible;
            transform: none;
        }
    }
`;

const MegaMenuAnchor = ({ ...props }) => {
    const theme = useTheme();

    return (
        <Anchor
            onClick={e => {
                if (!props.href || e.target.href === window.location.pathname) {
                    e.preventDefault();
                }
            }}
            onMouseUp={e => {
                e.target.blur();
            }}
            style={{
                display: 'inline-flex',
                flexDirection: 'row',
                alignItems: 'center',
                color: theme.colors.boldText,
                padding: '6px 20px',
            }}
            {...props}
        />
    );
};

const MobileMenuItem = ({ mt, border = true, w, ...props }: any) => (
    <>
        {border && (
            <Box
                mt={mt}
                height="1px"
                width="100%"
                borderTop="0.5px solid"
                borderTopColor="border"
            />
        )}
        <MegaMenuItem
            color="boldText"
            style={{
                justifyContent: 'left',
                height: 'auto',
                width: w ? w : '100%',
            }}
            p="spacing"
            px="spacing-lg"
            my="spacing-sm"
            // p={4}
            // px={8}
            my={2}
            mx={0}
            {...props}
        />
    </>
);

const MobileMenu = () => (
    <MenuContainer as="div">
        <MenuIcon size={24} />{' '}
        <MegaMenu className="menu">
            {/* <MobileMenuItem href="/app" border={false}>
                Community App
            </MobileMenuItem> */}
            <Grid
                columns={2}
                spacing="spacing"
            //borderTop="0.5px solid"
            //borderTopColor="border"
            >
                <Box minW={140}>
                    <MobileMenuItem href="/methods" border={false}>
                        Methods
                    </MobileMenuItem>
                    {methods.map(method => (
                        <MegaMenuItem
                            style={{
                                justifyContent: 'left',
                                margin: 0,
                                height: 'auto',
                                width: '100%',
                            }}
                            boxSizing="border-box"
                            p="spacing"
                            px="spacing-lg"
                            // p={4}
                            // px={8}
                            // pl={12}
                            fontSize="smallBody"
                            key={method[1]}
                            href={`/methods/${method[1]}`}
                        >
                            {method[0]}
                        </MegaMenuItem>
                    ))}
                </Box>
                <Box minW={140}>
                    <MobileMenuItem href="/principles" border={false}>
                        Principles
                    </MobileMenuItem>

                    {principles.map(principle => (
                        <MegaMenuItem
                            style={{
                                justifyContent: 'left',
                                margin: 0,
                                height: 'auto',
                                width: '100%',
                            }}
                            boxSizing="border-box"
                            p="spacing"
                            px="spacing-lg"
                            fontSize="smallBody"
                            key={principle}
                            href={`/principles/${principle.toLowerCase()}`}
                        >
                            {principle}
                        </MegaMenuItem>
                    ))}
                </Box>
                <Box minW={140}>
                    <MobileMenuItem
                        border={false}
                        onClick={e => e.preventDefault()}
                        w="17.5rem"
                    >
                        For Communities
                    </MobileMenuItem>
                    {forCommunities.map(method => (
                        <MegaMenuItem
                            style={{
                                justifyContent: 'left',
                                margin: 0,
                                height: 'auto',
                                width: '100%',
                            }}
                            boxSizing="border-box"
                            p="spacing"
                            px="spacing-lg"
                            // p={4}
                            // px={8}
                            // pl={12}
                            fontSize="smallBody"
                            key={method[0]}
                            href={`/landing/${method[1]}`}
                        >
                            {method[0]}
                        </MegaMenuItem>
                    ))}
                </Box>
            </Grid>

            <MobileMenuItem mt={2} href="/company">
                Company
            </MobileMenuItem>

            <IntercomContactButton>
                {show => (
                    <MobileMenuItem onClick={show} href="/contact">Contact</MobileMenuItem>
                )}
            </IntercomContactButton>

        </MegaMenu>
    </MenuContainer>
);

export const Nav = () => {
    const theme = useTheme();
    let hasClick = 0;

    return (
        <Container as="nav">
            <ul>
                {/* <Box as="li" display={{ _: 'none', lg: 'inline-flex' }}>
                    <Anchor
                        style={{
                            color: theme.colors.boldText,
                            padding: '6px 20px',
                        }}
                        href="/app"
                    >
                        Community App
                    </Anchor>
                </Box> */}
                {/* // TODO: update naming and such as some point. just copying existing principles code for now */}
                <PrinciplesMenuLi
                    as="li"
                    display={{ _: 'none', lg: 'inline-flex' }}
                >
                    <MegaMenuAnchor href="/methods">
                        Methods
                        <ChevronUpDownIcon
                            style={{ marginLeft: '4px' }}
                            size={18}
                        />
                    </MegaMenuAnchor>

                    <MegaMenu className="principlesMenu">
                        {methods.map(method => (
                            <PrincipleMenuItem
                                href={`/methods/${method[1]}`}
                                key={method[1]}
                            >
                                {method[0]}
                            </PrincipleMenuItem>
                        ))}
                    </MegaMenu>
                </PrinciplesMenuLi>
                <PrinciplesMenuLi
                    as="li"
                    display={{ _: 'none', lg: 'inline-flex' }}
                >
                    <MegaMenuAnchor href="/principles">
                        Principles
                        <ChevronUpDownIcon
                            style={{ marginLeft: '4px' }}
                            size={18}
                        />
                    </MegaMenuAnchor>

                    <MegaMenu className="principlesMenu">
                        {principles.map(principle => (
                            <PrincipleMenuItem
                                href={`/principles/${principle.toLowerCase()}`}
                                key={principle}
                            >
                                {principle}
                            </PrincipleMenuItem>
                        ))}
                    </MegaMenu>
                </PrinciplesMenuLi>
                <PrinciplesMenuLi
                    as="li"
                    display={{ _: 'none', lg: 'inline-flex' }}
                >
                    <MegaMenuAnchor>
                        For Communities
                        <ChevronUpDownIcon
                            style={{ marginLeft: '4px' }}
                            size={18}
                        />
                    </MegaMenuAnchor>

                    <MegaMenu className="principlesMenu">
                        {forCommunities.map(method => (
                            <PrincipleMenuItem
                                href={`/landing/${method[1]}`}
                                key={method[0]}
                            >
                                {method[0]}
                            </PrincipleMenuItem>
                        ))}
                    </MegaMenu>
                </PrinciplesMenuLi>
                <Box as="li" display={{ _: 'none', lg: 'inline-flex' }}>
                    <Anchor
                        style={{
                            color: theme.colors.boldText,
                            padding: '6px 20px',
                        }}
                        href="/company"
                    >
                        Company
                    </Anchor>
                </Box>
                <Box as="li" display={{ _: 'inline-flex', lg: 'none' }}>
                    <MobileMenu />
                </Box>
                <Box as="li" ml={5} display={{ _: 'none', lg: 'inline-flex' }}>
                    <IntercomContactButton>
                        {show => (
                            <Button onClick={show} style={{ color: 'white' }} href="/contact">
                                Contact us
                            </Button>
                        )}
                    </IntercomContactButton>
                </Box>
            </ul>
        </Container>
    );
};

export const LandingNav = () => {
    return (
        <Container as="nav">
            <ul>
                <Box as="li" ml={5} display={{ _: 'none', lg: 'inline-flex' }}>
                    <IntercomContactButton>
                        {show => (
                            <Button onClick={show} style={{ color: 'white' }} href="/contact">
                                Contact us
                            </Button>
                        )}
                    </IntercomContactButton>
                </Box>
            </ul>
        </Container>
    );
};


function IntercomContactButton({ children }) {
    const show = useCallback((e) => {
        e.preventDefault();

        const Intercom = window['Intercom']

        Intercom("boot", {
            api_base: "https://api-iam.intercom.io",
            app_id: "awlgtkwp"
        });

        Intercom("show");
    }, []);

    return children(show)
}