import styled from '@emotion/styled';
import { Box } from 'components/Box';

export const Container = styled(Box)`
    ul {
        display: flex;
        list-style: none;
        padding: 0;

        li {
            // text-transform: uppercase;
            // font-size: ${props => props.theme.fontSizes.smallSubtitle};
            // letter-spacing: 1.5px;
            font-weight: ${props => props.theme.fontWeights.medium};
            // display: inline-flex;
            align-items: center;
        }
    }
`;
