import { Box, BoxProps } from 'components/Box';
import React from 'react';

export function SkipNav({
    href,
    buttonText,
    ...props
}: {
    href: string,
    buttonText?: string,
} & BoxProps) {
    return (
        <Box
            as="a"
            position="fixed"
            top="-200px"
            left="10%"
            right="10%"
            width="auto"
            maxWidth="500px"
            padding="20px"
            textAlign="center"
            href={href}
            zIndex="9999"
            mx="auto"
            background="#2B2BE1"
            letterSpacing="0.5px"
            textTransform="uppercase"
            color="#fff"
            opacity="0"
            transition=".3s opacity ease-in-out"
            css={{
                '&:focus': {
                    top: '45vh',
                    opacity: '1',
                    '&::before': {
                        content: "''",
                        display: 'block',
                        position: 'fixed',
                        top: '0',
                        left: '0',
                        right: '0',
                        bottom: '0',
                        background: 'rgba(0,0,0,.5)',
                        zIndex: '-1',
                        pointerEvents: 'none',
                    },
                },
            }}
        >
            {buttonText}
        </Box>
    );
}

export function SkipNavLocation({ id, ...props }: BoxProps) {
    return <Box id={id} {...props}></Box>;
}
