import { useEffect, useState } from 'react';

const mq =
    typeof window !== 'undefined' &&
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)');

export function useSystemColorScheme() {
    const [theme, setTheme] = useState(mq && mq.matches ? 'dark' : 'light');

    useEffect(() => {
        if (mq && typeof mq.addEventListener === 'function') {
            mq.addEventListener('change', e => {
                if (e.matches) {
                    setTheme('dark');
                } else {
                    setTheme('light');
                }
            });
        }
    }, []);

    return theme;
}
