import React, { useEffect, useState } from 'react';
import { Box } from '../Box';
import { Anchor } from '../Anchor';
import styled from '@emotion/styled';
import { Button } from '../Button';

const Tooltip = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '100%',
    left: -4,
    padding: theme.space.spacing,
    backgroundColor: theme.colors.tertiaryBackground,
    color: 'white',
    cursor: 'default',

    '&:before': {
        position: 'absolute',
        top: -8,
        left: 8,
        content: '""',
        display: 'inline-block',
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '0 8px 8px 8px',
        borderColor: 'transparent transparent #000 transparent',
    },
}));

export function BrandChangeTooltip({ showTooltip, setTooltip }) {
    useEffect(() => {
        if (localStorage.getItem('audentio_brandswitch_tooltip') !== 'false') {
            const showTooltip = document.referrer === 'https://www.audent.io/';

            setTooltip(showTooltip);
        }
    }, []);

    if (!showTooltip) return null;

    return (
        <Box
            style={{
                position: 'absolute',
                top: '100%',
                left: 0,
                width: 1,
                height: 1,
            }}
        >
            <Tooltip
                lineHeight="base"
                width={180}
                fontSize="smallBody"
                fontWeight="medium"
            >
                To go back to audent.io
                <br /> hover/tap on this icon
                <br />
                <Button
                    onClick={() => {
                        setTooltip(false);
                    }}
                    style={{
                        height: 24,
                        lineHeight: '24px',
                        paddingTop: 0,
                        paddingBottom: 0,
                        marginTop: 5,
                    }}
                    fill="white"
                >
                    Dismiss
                </Button>
            </Tooltip>
        </Box>
    );
}
