import React from 'react';
import { Box, BoxProps } from '../Box';

export function SmallSubtitle(props: BoxProps) {
    return (
        <Box
            as="span"
            color="faintText"
            display="inline-block"
            textTransform="uppercase"
            fontSize="smallSubtitle"
            letterSpacing="1.3px"
            lineHeight="base"
            fontWeight={500}
            {...props}
        />
    );
}
