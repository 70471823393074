import { scrollTo } from '@audentio/utils/src/scrollTo';
import { Anchor } from 'components/Anchor';
import { ContentWidth } from 'components/ContentWidth';
import { LandingNav, Nav } from 'components/Header/nav';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { moveTransition } from '../AnimatedContainer';
import { Box } from '../Box';
import { Flex } from '../Flex';
import {
    HeaderContainer,
    HeaderContent,
    MethodSubNavComp,
    SubNav,
} from './Header.styled';
import { Logo } from './Logo';

type HeaderProps = {
    hasBorder?: boolean,
    subnav?: { title: string, href: string }[],
    subnavTitle?: string,
    useLandingNav?: boolean,
    methodSubNavIcon?: string,
    methodSubNavColor?: string,
};

const SUBNAV_HEIGHT = 48 + 20; // spacing of 20px
const scrollToId = id => e => {
    e.preventDefault();

    const element = document.getElementById(id);
    const offsetTop = element?.offsetTop + element?.style.marginTop;

    try {
        history.pushState(null, null, '#' + id);
    } catch (e) {}

    scrollTo(+offsetTop - SUBNAV_HEIGHT);
};

const StandardSubNav = ({ subnavTitle, subnav }) => {
    return (
        <SubNav as="nav">
            <ContentWidth pr={0} maxWidth="containers.header">
                {subnavTitle && (
                    <Anchor
                        onClick={scrollToId('principles-nav')}
                        href="#principles-nav"
                    >
                        <strong>{subnavTitle}</strong>
                    </Anchor>
                )}
                <ol>
                    {subnav.map((item: any, index) => (
                        <li key={index}>
                            <Anchor
                                onClick={scrollToId(item.id)}
                                href={`#${item.id}`}
                            >
                                {item.label}
                            </Anchor>
                        </li>
                    ))}
                </ol>
            </ContentWidth>
        </SubNav>
    );
};

const AnimatedSubNavBar = ({ color }) => {
    const [hasRendered, setHasRendered] = useState(false);

    useEffect(() => {
        if (color) {
            setHasRendered(true);
        }

        return () => {
            setHasRendered(false);
        };
    }, [color]);

    return (
        <Box
            pos="absolute"
            w="100%"
            // height should match the color bar on Methods grid
            h="8px"
            bottom={0}
            left={0}
            overflow="hidden"
        >
            <AnimatePresence>
                {hasRendered && (
                    <motion.div
                        key={`${color}_bar`}
                        style={{
                            width: '100%',
                            height: '100%',
                            background: color,
                        }}
                        initial={{
                            x: '-100%',
                        }}
                        animate={{
                            x: '0%',
                        }}
                        exit={{
                            x: '-100%',
                        }}
                        transition={moveTransition}
                    />
                )}
            </AnimatePresence>
        </Box>
    );
};

const MethodSubNav = ({
    methodSubNavColor,
    methodSubNavIcon,
    subnavTitle,
    subnav,
}) => {
    return (
        <MethodSubNavComp
            as="nav"
            methodSubNavColor={methodSubNavColor}
            pos={{
                _: 'relative',
                md: 'sticky',
            }}
        >
            <ContentWidth
                pl={[0, null, 'spacing-lg']}
                pr={0}
                maxWidth="containers.header"
                position="static"
            >
                <Flex
                    flex={1}
                    align="center"
                    justify="space-between"
                    pos={{
                        _: 'static',
                        md: 'relative',
                    }}
                >
                    {subnavTitle && (
                        <Box
                            backgroundColor={methodSubNavColor}
                            p="14px"
                            color="#000"
                            display="flex"
                            alignItems="center"
                        >
                            <img
                                alt=""
                                width="22px"
                                height="22px"
                                src={methodSubNavIcon}
                            />
                            <strong>{subnavTitle}</strong>
                        </Box>
                    )}
                    <ol>
                        {subnav.map((item: any, index) => (
                            <li key={index}>
                                <Anchor
                                    onClick={scrollToId(item.id)}
                                    href={`#${item.id}`}
                                >
                                    {item.label}
                                </Anchor>
                            </li>
                        ))}
                    </ol>

                    <AnimatedSubNavBar color={methodSubNavColor} />
                </Flex>
            </ContentWidth>
        </MethodSubNavComp>
    );
};

export const Header = ({
    hasBorder = true,
    subnav,
    subnavTitle,
    useLandingNav,
    methodSubNavIcon,
    methodSubNavColor,
}: HeaderProps) => {
    return (
        <>
            <HeaderContainer hasSubnav={!!subnav} hasBorder={hasBorder}>
                <ContentWidth maxWidth="containers.header">
                    <HeaderContent>
                        <Logo />

                        {useLandingNav ? <LandingNav /> : <Nav />}
                    </HeaderContent>
                </ContentWidth>
            </HeaderContainer>

            {/* Sub navigation */}
            {methodSubNavColor && (
                <MethodSubNav
                    subnav={subnav}
                    subnavTitle={subnavTitle}
                    methodSubNavIcon={methodSubNavIcon}
                    methodSubNavColor={methodSubNavColor}
                />
            )}
            {!methodSubNavColor && subnav && (
                <StandardSubNav subnav={subnav} subnavTitle={subnavTitle} />
            )}
        </>
    );
};
