import { motion } from 'framer-motion';
import React from 'react';
import { useWindowResize } from 'src/helpers/useWindowResize';
import { useTheme } from '../ThemeProvider';

export const moveTransition = {
    type: 'spring',
    damping: 20,
    mass: 1,
    stiffness: 80,
    delay: 0.5,
};

interface AnimatedContainerProps {
    children: any;
    isAnimated: boolean;
    offset: {
        initial: Object,
        final: Object,
    };
    allowMobileAnimation?: boolean;
    transitionProps?: Object;
}

export const AnimatedContainer = ({
    isAnimated,
    children,
    offset,
    allowMobileAnimation = false,
    transitionProps = {},
    ...props
}: AnimatedContainerProps) => {
    const { breakpoint } = useTheme();
    const { windowWidth } = useWindowResize();

    // unless otherwise specified, limit animation to desktop
    const allowAnimation =
        isAnimated &&
        (windowWidth > breakpoint.stripped.lg || allowMobileAnimation);

    const transition = {
        ...moveTransition,
        ...transitionProps,
    };

    return (
        <motion.div
            animate={allowAnimation ? 'moved' : 'unmoved'}
            variants={{
                unmoved: {
                    ...offset.initial,
                    transition,
                },
                moved: {
                    ...offset.final,
                    transition,
                },
            }}
            {...props}
        >
            {children}
        </motion.div>
    );
};
