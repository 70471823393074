import styled from '@emotion/styled';
import { Anchor } from 'components/Anchor';
import AppsIcon from 'mdi-react/AppsIcon';
import React, { useCallback, useState } from 'react';
import { Box } from '../Box';
import { BrandChangeTooltip } from '../BrandChangeTooltip';
import LogoSvg from './Logo.svg';
import { SiteSwitcher } from './SiteSwitcher';

const ByAudentio = styled(Box)`
    position: relative;
    bottom: -3px;
    font-size: ${props => props.theme.fontSizes.smallBody};
    text-transform: lowercase;
    color: ${props => props.theme.colors.bodyText};
    transition: 200ms ease;
`;

const SwitchTrigger = styled(Box)`
    margin-left: -7px;
    text-align: center;
    height: 36px;
    width: 36px;
    margin-right: 10px;
    border-radius: 30px;
    line-height: 50px;
    transition: 200ms ease;
    transition-delay: 400ms;

    &:hover,
    &:focus-within {
        transition-delay: 0ms;
        color: white;
        background-color: ${props => props.theme.colors.tertiaryBackground};
    }

    .siteSwitchAnchor {
        transition-delay: 400ms;
    }

    &:hover,
    &:focus-within {
        .siteSwitchAnchor {
            color: white;
            transition-delay: 0ms;
        }
    }

    .siteSwitcher {
        padding-left: 0;
        padding-right: 0;
        transition-delay: 400ms;
        // margin-top: 17px;
    }

    &:hover .siteSwitcher,
    &:focus .siteSwitcher,
    &:focus-within .siteSwitcher {
        visibility: visible;
        opacity: 1;
        transform: translateY(0px);
    }
`;

const StyledLogo = styled(Box)`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 0;
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: transparent;

    &:hover ${SwitchTrigger} {
        opacity: 1;
    }

    .siteSwitcher {
        margin-top: 15px;
    }

    .siteSwitcher_active {
        transition-delay: 0ms;
        visibility: visible;
        opacity: 1;
        transform: translateY(0px);
    }

    &:hover ${ByAudentio} {
        color: ${props => props.theme.colors.boldText};
    }
`;

export function Logo() {
    const [showTooltip, setTooltipState] = useState(false);
    const setTooltip = useCallback(state => {
        setTooltipState(state);
        localStorage.setItem('audentio_brandswitch_tooltip', state);
    }, []);

    return (
        <StyledLogo as="div">
            <BrandChangeTooltip
                showTooltip={showTooltip}
                setTooltip={setTooltip}
            />

            <SwitchTrigger
                onMouseEnter={() => {
                    setTooltip(false);
                }}
                //tabIndex="0"
            >
                <Anchor
                    onMouseEnter={() => {
                        setTooltip(false);
                    }}
                    className="siteSwitchAnchor"
                >
                    <AppsIcon size={24} />
                </Anchor>

                <SiteSwitcher className="siteSwitcher" />
            </SwitchTrigger>

            <Anchor href="/">
                <LogoSvg />
            </Anchor>
        </StyledLogo>
    );
}
