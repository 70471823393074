import { Heading } from 'components/Heading';
import { SmallSubtitle } from 'components/SmallSubtitle';
import React from 'react';
import { Box } from '../Box';

export const DualHeading = ({
    title,
    subtitle,
    subtitleColor,
    subtitleMarginBottom = 6,
    kind,
    ...props
}) => {
    return (
        <Heading kind={kind} {...props}>
            <SmallSubtitle mb={subtitleMarginBottom} color={subtitleColor}>
                {subtitle}
            </SmallSubtitle>
            <Box>{title}</Box>
        </Heading>
    );
};
