import React from 'react';
import { Box, BoxProps } from '../Box';
import { ContentWidth } from '../ContentWidth';

interface ISection {
    wrap?: boolean;
    children?: any;
    vertical?: 'sm' | 'md' | 'lg';
}

export type SectionProps = ISection & BoxProps;

export const sectionSpacing = {
    sm: ['spacing', 'spacing-lg', '40px'],
    md: ['spacing-lg', '40px', 'spacing-xl'],
    lg: ['40px', 'spacing-xl', '80px'],
};

export const Section = ({
    wrap = false,
    children,
    vertical = 'md',
    maxWidth,
    ...rest
}: SectionProps) => {
    if (!wrap) {
        return (
            <Box
                px={['spacing', null, 'spacing-lg']}
                py={sectionSpacing[vertical]}
                bg="primaryBackground"
                boxSizing="border-box"
                {...rest}
            >
                {children}
            </Box>
        );
    }

    return (
        <Box
            py={sectionSpacing[vertical]}
            bg="primaryBackground"
            boxSizing="border-box"
            {...rest}
        >
            <ContentWidth maxWidth={maxWidth}>{children}</ContentWidth>
        </Box>
    );
};
