import styled from '@emotion/styled';
import { Anchor } from 'components/Anchor';
import { Box } from '../Box';

export const Container =styled(Box)`
    position: absolute;
    top: 60px;
    left: -${props => props.theme.radii.lg};
    // background: ${props => props.theme.colors.primaryBackground};
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 0 0.5px ${props => props.theme.colors.boldBorder}, ${props => props.theme.shadows['2xl']};
    border-radius: ${props => props.theme.radii.lg};
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    cursor: default;
    visibility: hidden;
    transform: translateY(-20px);
    opacity: 0;
    transition: 300ms ease;
    z-index: ${props => props.theme.zIndices.modal + 1};

    backdrop-filter: blur(30px);
`;

export const ItemContainer = styled(Anchor)<{ active?: boolean }>`
    // width: 100px;
    // height: 100px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: ${props => props.theme.radii.lg};
    color: ${props => props.theme.colors.boldText};
    box-sizing: border-box;

    ${props => props.active && `
        // border: 2px solid ${props.theme.colors.blue[500]};
        background: rgba(0, 0, 0, 0.05);
        cursor: default;
    `}

    &:hover {
        background: rgba(0, 0, 0, 0.05);
        // background: rgba(0, 0, 0, 1);
        // color: white;
    }
`;
