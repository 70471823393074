import { css, Global } from '@emotion/core';
import { ThemeType } from 'components/theme';
import { withPrefix } from 'gatsby';

export const GlobalStyles = () => (
    <Global
        styles={(theme: ThemeType) =>
            css`
              html, body, div, span, applet, object, iframe,
              h1, h2, h3, h4, h5, h6, p, blockquote, pre,
              a, abbr, acronym, address, big, cite, code,
              del, dfn, em, img, ins, kbd, q, s, samp,
              small, strike, strong, sub, sup, tt, var,
              b, u, i, center,
              dl, dt, dd, ol, ul, li,
              fieldset, form, label, legend,
              table, caption, tbody, tfoot, thead, tr, th, td,
              article, aside, canvas, details, embed,
              figure, figcaption, footer, header, hgroup,
              menu, nav, output, ruby, section, summary,
              time, mark, audio, video {
                margin: 0;
                padding: 0;
                border: 0;
                font-size: 100%;
                font: inherit;
                vertical-align: baseline;
              }
          
              /* HTML5 display-role reset for older browsers */
              article, aside, details, figcaption, figure,
              footer, header, hgroup, menu, nav, section {
                display: block;
              }
          
              html {
                font-size: 62.5%;
              }
          
              body {
                font-family: "Larsseit", -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
                font-display: swap;
                line-height: 1;
                font-size: ${theme.fontSizes.body};
                color: ${theme.colors.bodyText};
                background-color: #fff;
                -webkit-text-size-adjust: 100%;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                text-rendering: optimizeLegibility;
                -webkit-font-feature-settings: "pnum";
                font-feature-settings: "pnum";
                font-variant-numeric: proportional-nums;
                box-sizing: border-box;
              }
          
              ol, ul {
                list-style: none;
              }
          
              blockquote, q {
                quotes: none;
              }
          
              blockquote:before, blockquote:after,
              q:before, q:after {
                content: '';
                content: none;
              }
          
              table {
                border-collapse: collapse;
                border-spacing: 0;
              }
          
              a {
                text-decoration: none;
                color: ${theme.colors.primary[500]};
              }
          
              pre {
                display: block;
                padding: 2rem;
                margin-top: 4rem;
                overflow: auto;
                font-size: 85%;
                line-height: 1.45;
                border-radius: 5px;
                color: ${theme.colors.primary[500]};
                border: 1px solid #ddd;
                font-family: "SFMono-Regular",Consolas,"Liberation Mono",Menlo,Courier,monospace;
              }
          
              video {
                max-width: 100%;
              }
          
          
            /* Larsseit Light*/
            @font-face {
                font-family: 'Larsseit';
                src: url('${withPrefix(`/fonts/Larsseit`)}/Larsseit-Light.eot');
                src: url('${withPrefix(
                    `/fonts/Larsseit`
                )}/Larsseit-Light.eot?#iefix') format('embedded-opentype'),
                    url('${withPrefix(
                        `/fonts/Larsseit`
                    )}/Larsseit-Light.woff') format('woff'),
                    url('${withPrefix(
                        `/fonts/Larsseit`
                    )}/Larsseit-Light.ttf') format('truetype');
                font-weight: 300;
                font-style: normal;
                font-display: swap
            }
            /*// Larsseit Regular*/
            @font-face {
                font-family: 'Larsseit';
                src: url('${withPrefix(`/fonts/Larsseit`)}/Larsseit.eot');
                src: url('${withPrefix(
                    `/fonts/Larsseit`
                )}/Larsseit.eot?#iefix') format('embedded-opentype'),
                    url('${withPrefix(
                        `/fonts/Larsseit`
                    )}/Larsseit.woff') format('woff'), url('${withPrefix(
                `/fonts/Larsseit`
            )}/Larsseit.ttf') format('truetype');
                font-weight: normal;
                font-style: normal;
                font-display: swap
            }
            /*// Larsseit Medium*/
            @font-face {
                font-family: 'Larsseit';
                src: url('${withPrefix(
                    `/fonts/Larsseit`
                )}/Larsseit-Medium.eot');
                src: url('${withPrefix(
                    `/fonts/Larsseit`
                )}/Larsseit-Medium.eot?#iefix') format('embedded-opentype'),
                    url('${withPrefix(
                        `/fonts/Larsseit`
                    )}/Larsseit-Medium.woff') format('woff'),
                    url('${withPrefix(
                        `/fonts/Larsseit`
                    )}/Larsseit-Medium.ttf') format('truetype');
                font-weight: 500;
                font-style: normal;
                font-display: swap
            }
            /*// Larsseit Bold*/
            @font-face {
                font-family: 'Larsseit';
                src: url('${withPrefix(`/fonts/Larsseit`)}/Larsseit-Bold.eot');
                src: url('${withPrefix(
                    `/fonts/Larsseit`
                )}/Larsseit-Bold.eot?#iefix') format('embedded-opentype'),
                    url('${withPrefix(
                        `/fonts/Larsseit`
                    )}/Larsseit-Bold.woff') format('woff'),
                    url('${withPrefix(
                        `/fonts/Larsseit`
                    )}/Larsseit-Bold.ttf') format('truetype');
                font-weight: 700;
                font-style: normal;
                font-display: swap
            }
          `
        }
    />
);
