import { Head } from 'components/Head';
import { Header } from 'components/Header';
import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import { GlobalStyles } from '../../global.styled';
import { Box } from '../Box';
import { ContactForm } from '../ContactForm';
import { Section } from '../Section';
import { SkipNav, SkipNavLocation } from '../SkipNav';

const LayoutComponent = ({
    data,
    contactForm,
    children,
    subnav,
    subnavTitle,
    pageTitleFull,
    pageTitle,
    siteDescription,
    useLandingNav,
    methodSubNavIcon,
    methodSubNavColor,
    wrapChildren = false,
}) => {
    const title = pageTitle || subnavTitle || 'Audentio';

    return (
        <>
            <GlobalStyles />
            <Head
                pageTitle={title}
                pageTitleFull={pageTitleFull || title}
                siteDescription={siteDescription}
            />
            <SkipNavLocation id="beforeNav" />
            <SkipNav href="#beforeContent" buttonText="Skip To Content" />
            {useLandingNav ? (
                <Header
                    subnavTitle={subnavTitle}
                    subnav={subnav}
                    useLandingNav
                />
            ) : (
                <Header
                    subnavTitle={subnavTitle}
                    subnav={subnav}
                    methodSubNavIcon={methodSubNavIcon}
                    methodSubNavColor={methodSubNavColor}
                />
            )}
            <SkipNavLocation id="beforeContent" />

            {wrapChildren ? <Box overflow="hidden">{children}</Box> : children}

            {contactForm && (
                <Section wrap vertical="lg">
                    <ContactForm source={`ac_contact_${title}`} />
                </Section>
            )}
            <SkipNav href="#beforeNav" buttonText="Back To Top" />
        </>
    );
};

export const Layout = props => (
    <StaticQuery
        query={graphql`
            query LayoutQuery {
                site {
                    siteMetadata {
                        siteTitle
                        siteDescription
                    }
                }
            }
        `}
        render={data => <LayoutComponent data={data} {...props} />}
    />
);
