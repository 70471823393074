import { isExternalUrl } from '@audentio/utils/src/isExternalUrl';
import { Box } from 'components/Box';
import { Link } from 'gatsby';
import React, { Component } from 'react';

/**
 * Anchor component (internal and external links)
 */
export class Anchor extends Component<any> {
    static defaultProps = {
        href: '#',
    };

    getHref() {
        const { href } = this.props;

        if (typeof window !== 'undefined') {
            if (href && href.indexOf(window.location.origin) === 0) {
                return href.replace(window.location.origin, '');
            }
        }

        return href;
    }

    render() {
        const {
            children,
            innerRef,
            _innerRef,
            external,
            onClick,
            ...props
        } = this.props;

        const href = this.getHref();

        // is external path
        if (external || isExternalUrl(href)) {
            return (
                <Box
                    as="a"
                    ref={innerRef || _innerRef}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={href}
                    {...props}
                >
                    {children}
                </Box>
            );
        }

        // const LinkTag = this.props.hash ? HashLink : Link;

        // Internal path
        // react-router Link
        return (
            <Box
                as={Link}
                ref={innerRef || _innerRef}
                to={href}
                onClick={e => {
                    if (onClick) onClick(e);

                    setTimeout(() => {
                        window.scrollTo(0, 0);
                    }, 100);
                }}
                {...props}
            >
                {children}
            </Box>
        );
    }
}
